<template>
  <div class="container">
    <div class="userCenter" @click="toCenter">
        <i class="iconfont icon-weibiaoti-_huaban el-icon" style="color: #000;"></i>
    </div>
    <!-- listening动画组件 -->
    <div v-if="!isThinking && !isReplying" :class="isListening?'main-animation':''" class="main"></div>
    <!-- 思考动画组件 -->
    <div v-if="isThinking && !isReplying" class="brainstorm-container">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
    <div v-if="isThinking && !isReplying" class="brainstorm-container brainstorm-mini">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
    <audio @ended="audioEnded()" muted style="position: absolute; right: 0%; bottom: 100%; opacity: 0;"
      ref="audioPlayer" controls="controls">
    </audio>
    <!-- 语音输入动画组件 -->
    <div v-if="isReplying && !isListening" class="replyingWaves" style="">
      <div class="wave-container replay-container">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
    </div>

    <!-- <b-icon icon="mic-mute" v-if="isThinking || isReplying"></b-icon>
    <b-icon icon="mic" v-else @click="startListening()"></b-icon> -->
    <div class="videos">
      <i class="iconfont icon-maikefeng" v-if="isThinking || isReplying" :style="isThinking || isReplying?'margin-bottom: 1.8rem':''"></i>
      <i class="iconfont icon-maikefeng-xue mouseHead" v-else @click="startListening()"></i>
      <div class="wave-container" v-show="isListening">
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
        <div class="wave"></div>
      </div>
    </div>
    <div class="balls"></div>
    <span class="tips" :class="isReplying?'tipsReplying':''">{{tips}}</span>

    <!-- <b-icon v-show="isListening || isThinking || isReplying" icon="x-circle-fill" @click="stopListening()"></b-icon> -->
    <div class="videos"><i class="iconfont icon-guanbi1" v-show="isListening || isThinking || isReplying" @click="stopListening()" :style="isReplying?'margin-top: 56px':''"></i></div>
  </div>
</template>
<script>
  import {
    getWsAddr
  } from '../../config/request.js'
  import Recorder from 'js-audio-recorder'
  export default {
    name: '',
    data() {
      return {
        isListening: false,
        isThinking: false,
        isReplying: false,
        isConnected: false,
        tips: '正在连接...',
        connectFreq: 1,
        connectInterval: null,
        chatSocket: null,
        WS_ADDR: getWsAddr(),
        userToken: null,
        recorder: null,
        audioPlayer: null,
        quitTimeout: null,
        detechSpeechInteral: null,
        speechStartTime: 0,
        recordStartTime: 0,
        recordTimeCursor: 0,
        sampleRate: 16000,
        numChannels: 1
      }
    },
    methods: {
      automaticSpeechRecognition() {
        let that = this

        setTimeout(function() {
          that.tips = "正在听...."
          navigator.mediaDevices.getUserMedia({
            audio: true
          }).then(stream => {
            console.log('success')
            const audioContext = new AudioContext();
            const audioSource = audioContext.createMediaStreamSource(stream);

            const analyzerNode = audioContext.createAnalyser();
            analyzerNode.fftSize = 2048;

            audioSource.connect(analyzerNode);

            function detechSpeech() {
              const bufferLength = analyzerNode.frequencyBinCount;
              const dataArray = new Uint8Array(bufferLength);

              console.log("检测声音...");
              if (!that.isListening){

                return;
              }

              analyzerNode.getByteFrequencyData(dataArray);
              const threshold = 180;
              for (let i = 0; i < bufferLength; i++) {
                if (dataArray[i] >= threshold) {
                  console.log("有人在说话：", dataArray[i]);
                  clearTimeout(that.quitTimeout);
                  that.speechStartTime = performance.now();
                  if (that.recordTimeCursor == 0) {
                    that.recordTimeCursor = that.speechStartTime;
                  }
                  return;
                }
              }

              if (that.speechStartTime > 0 || performance.now() - that.recordStartTime >= 90000) {
                that.speechEndTime = performance.now();
                if (that.speechEndTime - that.speechStartTime > 3000 || performance.now() - that.recordStartTime >= 90000) {

                  console.log("说话完成");
                  that.recorder.stop();
                  clearInterval(that.detechSpeechInteral);
                  that.tips = "";
                  that.isListening = false;
                  that.isThinking = true;
                  that.speechStartTime = 0;

                  // console.log((that.recordTimeCursor - that.recordStartTime), "秒后开始说话")

                  const audioBlob = that.recorder.getWAVBlob();

                  const fileReader = new FileReader();
                  fileReader.onload = function(event) {
                    const base64Audio = event.target.result;
                    that.chatSocket.send(JSON.stringify({
                      "base64Data": base64Audio,
                      "timeCursor": that.recordTimeCursor - that.recordStartTime
                    }));
                    // that.chatSocket.send(base64Audio);
                    that.chatSocket.send("end");
                    that.recorder.destroy();
                    console.log("销毁录音");
                    that.recordTimeCursor = 0;
                  };
                  fileReader.readAsDataURL(audioBlob);
                }

                return;
              }
              if (that.speechStartTime == 0) {
                console.log("无人说话");
              }

            }

            that.recordStartTime = performance.now();

            that.detechSpeechInteral = setInterval(detechSpeech, 100);
          })
        }, 1000)
      },
      startListening() {
        if (this.isConnected) {
          console.log("创建录音")
          this.recorder = new Recorder({
            sampleRate: this.sampleRate,
            numChannels: this.numChannels
          });

          this.recorder.start();
          this.tips = "请开始讲话"
          this.isListening = true;
          this.isThinking = false;
          this.isReplying = false;
          this.speechStartTime = 0;
          this.audioPlayer.muted = false;
          this.audioPlayer.pause();
          this.automaticSpeechRecognition();
        }
      },
      stopListening() {
        clearInterval(this.detechSpeechInteral);
        this.isListening = false;
        this.isThinking = false;
        this.isReplying = false;
        this.recorder.stop();
        this.audioPlayer.pause();
        this.tips = "点击麦克风开始说话";
        this.audioPlayer.currentTime = 0;
        this.recordTimeCursor = 0;
      },
      audioEnded() {
        this.startListening();
        let that = this
        this.quitTimeout = setTimeout(function(){
          that.stopListening();
        },45000);
      },
      eatablelishConn() {
        let userData = localStorage.getItem("userData");
        if (!userData || userData === 'null' || userData === '/'){
          location.href = "/login"
        }
        this.userToken = JSON.parse(userData)["token"];
        // this.userToken = "2d08cfb779b12eb4ee4c908edc117b4a09063c26"
        let channel = this.userToken.substring(0, 10) + Date.now()

        this.chatSocket = new WebSocket(
          `${this.WS_ADDR}/ws/speech/${channel}_${this.userToken}/`
        );
      },
      toCenter(){
        location.href = '/userInfo'
      }
    },
    mounted() {
      let that = this
      if (!this.isConnected) {

        this.connectInterval = setInterval(function() {
          if (that.connectFreq > 3) {
            that.connectFreq = 0;
          }
          let symbol = " .".repeat(that.connectFreq);
          that.tips = "正在连接" + symbol;
          that.connectFreq += 1
        }, 1000);
      }

      this.eatablelishConn();
      console.log('mounted hook called'); // 验证 mounted 钩子是否被调用
      console.log('navigator.mediaDevices:', navigator.mediaDevices); // 输出 navigator.mediaDevices 的当前状态
      navigator.mediaDevices.getUserMedia({
        audio: true
      });

      this.chatSocket.onopen = function() {
        console.log("连接成功");
        clearInterval(that.connectInterval);

        that.isConnected = true;
      }

      this.chatSocket.onclose = function() {
        that.isListening = false;
        that.isThinking = false;
        that.isReplying = false;
        that.isConnected = false;
        console.log("连接已关闭");
        that.tips = "连接已断开";
        if (that.recorder)
          that.recorder.stop();
        that.audioPlayer.pause();
        clearInterval(that.detechSpeechInteral);
      }

      this.audioPlayer = this.$refs.audioPlayer;

      this.chatSocket.onmessage = function(event) {
        const data = JSON.parse(event.data);

        if ("audio_data" in data) {
          const audioBase64 = data.audio_data;

          const audioBytes = atob(audioBase64);

          let audioArray = new Uint8Array(audioBytes.length);

          for (let i = 0; i < audioBytes.length; i++) {
            audioArray[i] = audioBytes.charCodeAt(i);
          }

          const audioBlob = new Blob([audioArray], {
            type: 'audio/mp3'
          });
          const audioUrl = URL.createObjectURL(audioBlob);

          that.audioPlayer.src = audioUrl;

          // 思考完成，开始回复
          if (that.isThinking){
            that.tips = ""
            that.isReplying = true;
            that.audioPlayer.play();

          }

          that.isThinking = false;

        } else {
          that.tips = data["msg"];
          if (that.tips == "连接已断开") {
            setTimeout(function() {
              location.href = "/login";
            }, 1000)
          }

        }
      }
    }
  }
</script>
<style lang="less" scoped>
  @media screen and (max-width: 768px) {
    .container {

      .brainstorm-container {
        width: 40% !important;
        height: 200px !important;
        margin: 1.8rem auto 0 !important;
        padding-top: 5%;

        .circle {
          width: 18rem !important;
          height: 18rem !important;
        }
      }

      .wave-container {
        margin-top: 1rem !important;
      }

      .brainstorm-mini {
        width: 60px !important;
        height: 660px !important;
        margin-left: 10% !important;
        margin-top: 32% !important;
      }

      .replyingWaves {
        display: flex;
        justify-content: center;
        margin: 10rem auto 17rem!important;
          .replay-container {
            margin-top: 0 !important;
          .wave {
            height: 8rem !important;
            width: 5rem !important;
          }
        }
      }
    }
  }

  .container {
    max-width: 100%;
    background-color: #fff;
    color: #000;
    padding: 10rem 0 !important;
    height: 100vh;
    .videos{
      display: flex;
      justify-content: center;
      align-items: center;
      .iconfont {
        color: #0fa47f;
        font-size: 48px;
      }
      .icon-guanbi1{
        font-size: 18px;
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        color: #fff;
        background-color: #d24726;
        width: 48px;
        height: 48px;
        line-height: 48px;
        border-radius: 50%;
        text-align: center;
        bottom: 9rem;
      }
    }
    .userCenter {
      position: absolute;
      top: 0;
      left: 0;
      margin: 10px;
      display: inline-block;
      color: #fff;
      cursor: pointer;
    
      .icon-weibiaoti-_huaban {
        font-size: 22px;
      }
    }

    .main {
      width: 280px;
      height: 280px;
      border-radius: 50%;
      background-color: #0fa47f;
      margin: 0 auto;
      margin-top: 10rem;
      margin-bottom: 6rem;
    }

    .main-animation {
      animation: main-listen 2s infinite alternate;
    }

    // listening动画
    @keyframes main-listen {
      0% {
        transform: scale(1);
        filter: brightness(1);
      }

      50% {
        transform: scale(0.9);
        filter: brightness(1.1);
      }

      100% {
        transform: scale(1);
        filter: brightness(1);
      }
    }

    .brainstorm-container {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 84px auto 0;
      padding: 5% 0;

      /*   整体旋转动画   */
      animation: pushRotate 10s linear infinite;

      @keyframes pushRotate {
        to {
          transform: rotate(360deg);
          transform-origin: center center;
        }
      }

      .circle {
        position: absolute;
        width: 150px;
        height: 150px;
        background-color: #0fa47f;
        border-radius: 50%;
        animation: moveCircle 1s ease-in-out infinite;
      }

      .circle:nth-child(1) {
        top: -15%;
        left: -7.5%;
        transform-origin: -50px 0;
        animation-delay: 0s;
      }

      .circle:nth-child(2) {
        top: -15%;
        left: 30%;
        transform-origin: 0 100px;
        animation-delay: 0.2s;
      }

      .circle:nth-child(3) {
        top: 45%;
        left: 15%;
        transform-origin: 100px 0;
        animation-delay: 0.4s;
      }

      .circle:nth-child(4) {
        top: 25%;
        left: -10%;
        transform-origin: 0 100px;
        animation-delay: 0.6s;
      }

      .circle:nth-child(5) {
        top: 20%;
        left: 30%;
        animation-delay: 0.8s;
      }

      @keyframes moveCircle {

        0%,
        100% {
          transform: scale(1);
        }

        50% {
          transform: scale(0.9);
        }
      }
    }

    .brainstorm-mini {
      width: 50px !important;
      height: 50px !important;
      padding: 1%;
      margin: 80px auto 26px;
      margin-left: 40%;

      .circle {
        width: 30px !important;
        height: 30px !important;
      }

      .circle:nth-child(1) {
        top: 5px;
        left: 4px;
      }

      .circle:nth-child(2) {
        top: 8px;
        left: 10px;
      }

      .circle:nth-child(3) {
        top: 16px;
        left: 12px;
      }

      .circle:nth-child(4) {
        top: 18px;
        left: 4px;
      }

      .circle:nth-child(5) {
        top: 12px;
        left: 0px;
      }
    }

    .bi-mic,.bi-mic-mute {
      color: #000;
      font-size: 48px;

      &:hover {
        cursor: pointer;
      }
    }

    .bi-mic-mute {
      margin-top: 4rem;
    }

    .mic-listening {
      font-size: 24px !important;
    }

    .tips {
      display: block;
      margin-top: 2rem;
      text-align: center;
    }

    .wave-container {
      display: inline-block;

      .wave {
        height: 2rem;
        width: 1.8rem;
        border-radius: 40%;
        background-color: #0fa47f;
        display: inline-block;
        vertical-align: text-bottom;
        animation: jump 0.5s infinite alternate;
      }

      // 语音输入跳动动画
      @keyframes jump {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1, 0.7);
        }

        100% {
          transform: scale(1);
        }
      }

      .wave:nth-child(0) {
        animation-delay: 0s;
      }

      .wave:nth-child(1) {
        animation-delay: 0.5s;
      }

      .wave:nth-child(2) {
        animation-delay: 1s;
      }

      .wave:nth-child(3) {
        animation-delay: 1.5s;
      }
    }
    
    .replyingWaves {
      display: flex;
      justify-content: center;
      margin: 15rem auto 14rem;

      .replay-container {
        margin-bottom: 6rem;

        .wave {
          height: 9rem;
          width: 6rem;
        }
      }
    }

    .bi-x-circle-fill {
      color: rgb(239, 132, 123);
      font-size: 48px;
      margin-top: 6rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
  
</style>
<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .container {
    .main {
      width: 32rem;
      height: 32rem;
      border-radius: 50%;
      background-color: #0fa47f;
      margin: 1.1rem auto 7.5rem;
    }

    .tips {
      margin-top: 2.5rem;
      font-size: 1.8rem;
    }
    .tipsReplying{
      margin-top: 2.1rem;
    }
    .icon-guanbi1{
      font-size: 2rem !important;
      margin-top: 5rem !important;
      width: 5.5rem !important;
      height: 5.5rem !important;
      line-height: 5.5rem !important;
    }
    .brainstorm-mini {}
  }
}
</style>